<template>
  <v-card outlined>
    <v-card-title class="justify-center">
      Panel Login
    </v-card-title>
    <v-card-text
      v-if="errorMessage"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ errorMessage }}
      </v-alert>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        class="google-sign-in"
        @click="google"
      >
        <v-icon left>
          mdi-google
        </v-icon>
        Sign In With Google
      </v-btn>
    </v-card-actions>
    <v-card-text class="text-center">
      By signing in you agree to our <a href="https://fireful.io/tos" target="_blank">Terms</a> & <a href="https://fireful.io/privacy" target="_blank">Privacy Policy</a>.
    </v-card-text>
  </v-card>
</template>

<script>
import { auth, GoogleAuthProvider } from '@/plugins/firebase'
export default {
  name: 'Login',
  data: () => ({
    errorMessage: null
  }),
  methods: {
    async google() {
      try {
        const provider = new GoogleAuthProvider()
        provider.addScope('https://www.googleapis.com/auth/firebase')
        const { credential } = await auth.signInWithPopup(provider)
        credential.accessToken && window.localStorage.setItem('accessToken', credential.accessToken)
        this.$router.push('/')
      } catch (error) {
        this.errorMessage = error.message
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .google-sign-in
    background: white
    color: #444
    border-radius: 5px
    border: thin solid #888
    box-shadow: 1px 1px 1px grey
    white-space: nowrap
</style>
